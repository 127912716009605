<template>
    <default-template
        :title="'温熱・省エネ設備機器ポータルサイト'"
        :is-visible-public="false"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="true"
        :is-visible-administrator-header="true"
    >
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">リンク一覧</h2>
            </div>
            <error-list class="notice" />
            <administrator-links-form />
            <p v-if="total != 0">{{total}}件中{{start}}～{{end}}件表示</p>
            <table
                v-if="links.length !== 0"
                key="resultTable"
                ref="jest-result-table"
                class="lists_table"
            >
                <thead>
                    <tr>
                        <th>事業者名</th>
                        <th>カテゴリー名</th>
                        <th>URL</th>
                        <th>登録日</th>
                        <th class="lists_table_releaseYearMonth">承認日</th>
                        <th>種別</th>
                        <th class="lists_table_states">ステータス</th>
                        <th></th>
                    </tr>
                </thead>
                <tr
                    v-for="item in links"
                    :key="'links' + item.id"
                    :ref="'jest-links' + item.id"
                >
                    <td>{{item.organization_name}}</td>
                    <td class="category_name">
                        {{item.string_level1}} > {{item.string_level2}} > 
                        <span v-if="isLevel3(item)">{{item.string_level3}} > </span>
                        {{item.string_category_code}}
                    </td>
                    <td class="url">
                        <a
                            :href="item.url"
                            target="_blank"
                            class="uq_url"
                        >
                            {{item.url}}
                        </a>
                    </td>
                    <td>{{date(item.created_at)}}</td>
                    <td>{{date(item.approved_at)}}</td>
                    <td>{{forEvaluatorString(item)}}</td>
                    <td>{{stringApproved(item.approved)}}</td>
                    <td>
                        <app-button
                            name="承認"
                            @click="moveTo(item, '/admin/links/' + item.id + '/approved/enabled')"
                            :ref="'jest-links-approved-enabled' + item.id"
                            v-if="!item.approved"
                            class="inline wd_80px"
                        />
                        <app-button
                            name="承認取消"
                            class="delete inline wd_80px"
                            @click="moveTo(item, '/admin/links/' + item.id + '/approved/disabled')"
                            :ref="'jest-links-approved-disabled' + item.id"
                            v-if="item.approved"
                        />
                        <app-button
                            name="削除"
                            class="delete inline wd_80px"
                            @click="moveTo(item, '/admin/links/' + item.id + '/delete')"
                            :ref="'jest-links-delete' + item.id"
                            v-if="!item.approved"
                        />
                    </td>
                </tr>
            </table>
            <list-pager
                :page="page"
                :page-count="pageCount"
                @page-change="pageChange"
            />
            <p
                v-if="links.length === 0"
                key="resultParagraph"
                ref="jest-result-paragraph"
            >
                リンクがありません
            </p>
        </div>
    </default-template>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { move, mapComputedProperties, pageContentCount } from '@/util'
import Progress from '@/mixins/progress.js'
import AdministratorLinksForm from '@/components/organisms/AdministratorLinksForm'
import ErrorList from '@/components/molecules/ErrorList'
import ListPager from '@/components/molecules/ListPager'

export default {
    name: 'AdminLinks',
    mixins:[Progress],
    components:{
        AdministratorLinksForm,
        ErrorList,
        ListPager,
    },
    computed:{
        ...mapGetters({
            links:'admin/links/links',
            data:'admin/links/searchStatus'
        }),
        ...mapComputedProperties([
            'total',
            'page',
        ]),
        /**
         * リストの件数の合計から算出されるリストのページ数を返す
         */
        pageCount(){
            return Math.ceil(this.total / pageContentCount)
        },
        /**
         * 日付文字列を「YYYY年MM月DD日」の形式に成形
         */
        date(){
            return function (dateString) {
                return dateString ? moment(dateString).format('YYYY年MM月DD日') : '-'
            }
        },
        /**
         * level3の表示可否を確認
         */
        isLevel3(){
            return function (item) {
                return item.string_level3 !== null
            }
        },
        stringApproved(){
            return function (approved) {
                return approved ? '承認済' : '非承認'
            }
        },
        start(){
            return 1 + (this.page - 1) * pageContentCount
        },
        end(){
            const count = this.page * pageContentCount
            return count < this.total ? count : this.total
        }
    },
    async mounted(){
        this.getLinksList()
    },
    methods:{
        ...mapActions({
            getLinks:'admin/links/getLinks',
            update:'admin/links/update',
            clearError:'error/clear',
        }),
        /**
         * リンクリストのページの切り替え
         */
        async pageChange(page){
            const loader = this.$loading.show()
            await this.update({page})
            await this.getLinksList()
            loader.hide()
        },
        async getLinksList(){
            const loader = this.$loading.show()
            this.startProgress()
            await this.getLinks()
            this.finishProgress()
            loader.hide()
        },
        moveTo(item, path){
            this.update({link:item})
            this.clearError()
            move(this.$router, path)
        },
        forEvaluatorString(item) {
            return item.for_evaluator ? '評価機関専用' :'一般用'
        },
    }
}
</script>

<style scoped>
.bl_contents_wrapper{
    min-width: 100%;
    max-width: 100%;
}
.table.lists_table{
    width: 100%;
}
.lists_table tr td:last-child .el_button{
    margin-bottom: 4px;
}
.uq_url{
    display: inline;
    max-width: 15em;
}
.url{
    word-break:break-all;   
}
.category_name{
    max-width:40em;
}
</style>