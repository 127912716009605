<template>
    <div class="bl_searchBox_outer">
        <div class="bl_searchBox ly_searchBox">
            <div class="uq_flexcolumn">
                <div class="bl_input_container uq_grid1_label">
                    <app-label
                        name="type"
                        label="種別"
                        class="rowlabel grid1"
                    />
                    <app-radio
                        v-model="type"
                        label="住宅版"
                        value="house"
                    />
                    <app-radio
                        v-model="type"
                        label="非住宅版"
                        value="building"
                    />
                </div>
                <div class="bl_input_container uq_grid1_label">
                    <app-label
                        name="level1"
                        label="大分類"
                        class="rowlabel grid1"
                    />
                    <app-select
                        v-model="level1"
                        :label="'選択してください'"
                        ref="jest-links-level1"
                        :vmodel-label="level1"
                    >
                        <app-select-option
                            v-model="level1"
                            :value="null"
                            key="level1-null"
                        >
                            選択してください
                        </app-select-option>
                        <app-select-option
                            v-for="(item, index) in level1List"
                            v-model="level1"
                            :value="item"
                            :key="'level1' + index"
                        >
                            {{item}}
                        </app-select-option>
                    </app-select>
                </div>
                <div class="bl_input_container uq_grid1_label">
                    <app-label
                        name="level2"
                        label="中分類"
                        class="rowlabel grid1"
                    />
                    <app-select
                        v-model="level2"
                        :label="'選択してください'"
                        ref="jest-links-level2"
                        :vmodel-label="level2"
                    >
                        <app-select-option
                            v-model="level2"
                            :value="null"
                            key="level2-null"
                        >
                            選択してください
                        </app-select-option>
                        <app-select-option
                            v-model="level2"
                            v-for="(item, index) in level2List"
                            :value="item"
                            :key="'level2' + index"
                        >
                            {{item}}
                        </app-select-option>
                    </app-select>
                </div>
                <div class="bl_input_container uq_grid1_label">
                    <app-label
                        name="categoryCode"
                        label="カテゴリー名"
                        class="rowlabel grid1"
                    />
                    <app-select
                        v-model="categoryCode"
                        :label="'選択してください'"
                        ref="jest-links-category-code"
                        :vmodel-label="vmodelLabel"
                    >
                        <app-select-option
                            v-model="categoryCode"
                            :value="null"
                            key="category-code-null"
                        >
                            選択してください
                        </app-select-option>
                        <app-select-option
                            v-for="item in categoriesList"
                            v-model="categoryCode"
                            :value="item.code"
                            :key="'category' + item.code"
                        >
                            {{item.category}}
                        </app-select-option>
                    </app-select>
                </div>
            </div>
            
            <div class="uq_flexcolumn">
                <div class="bl_input_container uq_grid1_label">
                    <app-text
                        v-model="organizationName"
                        name="organizationName"
                        label="事業者名"
                        class="formrow"
                        ref="jest-links-organization-name"
                    />
                </div>
                <div class="bl_input_container uq_grid1_label">
                    <app-label
                        name="approved"
                        label="ステータス"
                        class="rowlabel grid1"
                    />
                    <app-radio
                        v-model="approved"
                        label="承認済"
                        :value="true"
                    />
                    <app-radio
                        v-model="approved"
                        label="非承認"
                        :value="false"
                    />
                </div>
                <div class="bl_input_container uq_grid1_label">
                    <app-label
                        label="期間の指定"
                        class="rowlabel grid1"
                    />
                    <app-radio
                        v-model="dateType"
                        value=""
                        label="指定しない"
                    />
                    <app-radio
                        v-model="dateType"
                        value="created_at"
                        label="登録日"
                    />
                    <app-radio
                        v-model="dateType"
                        value="updated_at"
                        label="更新日"
                    />
                    <app-radio
                        v-model="dateType"
                        value="approved_at"
                        label="承認日"
                    />
                </div>
                <div 
                    class="bl_input_container uq_grid1_label"
                >
                    <label
                        class="rowlabel grid1 el_label"
                    >
                        <span v-if="dateType == 'created_at'">登録日</span>
                        <span v-else-if="dateType == 'updated_at'">更新日</span>
                        <span v-else-if="dateType == 'approved_at'">承認日</span>
                    </label>
                    <app-text-box
                        name="startDate"
                        v-model="startDate"
                        label=""
                        class="formrow"
                        type="date"
                        ref="jest-orgnaization-start-date"
                        :disabled="dateType == ''"
                    />
                    ～
                    <app-text-box
                        name="endDate"
                        v-model="endDate"
                        label=""
                        class="formrow"
                        type="date"
                        ref="jest-orgnaization-finish-date"
                        :disabled="dateType == ''"
                    />
                </div>
                <app-button
                    name="検索"
                    @click="search"
                    ref="jest-links-search"
                    class="inline wd_100px uq_endbutton"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { mapComputedProperties } from '@/util'
import Progress from '@/mixins/progress.js'

export default {
    name: 'AdminLinksForm',
    mixins:[Progress],
    computed:{
        ...mapGetters({
            data:'admin/links/searchStatus',
            level1List:'admin/links/level1List',
            level2List:'admin/links/level2List',
            categoriesList:'admin/links/categoriesList',
        }),
        ...mapComputedProperties([
            'type',
            'level1',
            'level2',
            'categoryCode',
            'dateType',
            'startDate',
            'endDate',
            'organizationName',
            'approved',
        ]),
        /**
         * 日付文字列を「YYYY年MM月DD日」の形式に成形
         */
        date(){
            return function (dateString) {
                return dateString ? moment(dateString).format('YYYY年MM月DD日') : '-'
            }
        },
        vmodelLabel(){
            const item = this.categoriesList.filter((item) => item.code == this.categoryCode)[0]
            return this.categoryCode ? item.category : null
        }
    },
    async mounted () {
        this.updateCategories()
        this.updateCategoriesLevels()
    },
    watch:{
        type(){
            this.updateCategories()
            this.updateCategoriesLevels()
            this.update({
                level1:null,
                level2:null,
                categoryCode:null,
            })
        },
        level1(){
            this.update({
                level2:null,
                categoryCode:null,
            })
        },
        level2(){
            this.update({
                categoryCode:null,
            })
        },
        dateType(value){
            if(value === '') {
                this.startDate = null
                this.endDate = null
            }
        }
    },
    methods:{
        ...mapActions({
            getLinks:'admin/links/getLinks',
            update:'admin/links/update',
            clearError:'error/clear',
            updateError:'error/update',
            updateCategories:'admin/links/updateCategories',
            updateCategoriesLevels:'admin/links/updateCategoriesLevels',
        }),
        /**
         * リンクリストの検索
         */
        async search(){
            await this.update({page:1})
            this.getLinksList()
        },
        async getLinksList(){
            this.clearError()
            const loader = this.$loading.show()
            this.startProgress()
            await this.getLinks()
                .then(response => {
                    if(response.status !== 200) {
                        this.updateError(response)
                    }
                })
            this.finishProgress()
            loader.hide()
        },
    }
}
</script>

<style scoped>
.ly_searchBox{
    align-items: flex-start;
    justify-content: flex-start;
}
.uq_flexcolumn{
    display: flex;
    flex-direction: column;
}
.uq_flexcolumn:first-child{
    margin-right: 16px;
}
.uq_flexcolumn >>> .select_btn{
    min-width: 15em;
}
.uq_flexcolumn >>> .select_wrapper label{
    width: 100%;
}
.uq_flexcolumn >>> label.grid1{
    width: 9em;
    font-weight: 600;
    font-size: 12px;
}
.uq_grid1_label >>> .el_label.grid1{
    width: 6em;
}
.uq_flexcolumn >>> .radio>label{
    font-size: 14px;
}
button.el_button.uq_endbutton{
    margin: 8px 0 0 auto;
}
</style>