import { render, staticRenderFns } from "./AdministratorLinksForm.vue?vue&type=template&id=34297fb8&scoped=true&"
import script from "./AdministratorLinksForm.vue?vue&type=script&lang=js&"
export * from "./AdministratorLinksForm.vue?vue&type=script&lang=js&"
import style0 from "./AdministratorLinksForm.vue?vue&type=style&index=0&id=34297fb8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34297fb8",
  null
  
)

export default component.exports