<template>
    <div class="form_box ly_form_box ly_linkform">
        <div class="formrow">
            <app-label
                name="事業者名"
                label="事業者名"
                class="rowlabel grid1"
            />
            <p>{{link.organization.name}}</p>
        </div>
        <div class="formrow">
            <app-label
                name="登録カテゴリー"
                label="登録カテゴリー"
                class="rowlabel grid1"
            />
            <p>
                {{link.string_level1}} > {{link.string_level2}} > 
                <span v-if="isLevel3">{{link.string_level3}} > </span>
                {{link.string_category_code}}
            </p>
        </div>
        <div class="formrow">
            <app-label
                name="URL"
                label="URL"
                class="rowlabel grid1"
            />
            <a
                :href="link.url"
                target="_blank"
            >
                {{link.url}}
            </a>
        </div>
        <div v-if="link.for_evaluator" class="formrow">
            <app-label
                name="memo"
                label="リンクテキスト"
                class="rowlabel grid1"
                ref="jest-links-memo"
            />
            <p>{{link.memo}}</p>
        </div>
        <div class="formrow">
            <app-label
                name="for_evaluator"
                label="リンクの種類"
                class="rowlabel grid1"
            />
            <p>{{forEvaluatorString}}</p>
        </div>
    </div>
</template>

<script>
/**
 * 管理者用リンク情報確認画面
 */
import { mapGetters } from 'vuex'

export default {
    name:'AdministratorLinkConfirm',
    computed:{
        ...mapGetters({
            link:'admin/links/link',
        }),
        forEvaluatorString() {
            return this.link.for_evaluator ? '評価機関専用' :'一般用'
        },
        isLevel3(){
            return this.link.string_level3 !== null
        }
    },
}
</script>

<style scoped>
.ly_linkform .formrow{
    grid-template-columns: 9em 1fr;
    -ms-grid-columns: 9em 1fr;
}
</style>