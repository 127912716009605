<template>
    <default-template
        :title="'温熱・省エネ設備機器ポータルサイト'"
        :is-visible-public="false"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="true"
        :is-visible-administrator-header="true"
    >
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">リンク{{typeString}}</h2>
            </div>
            <p>以下のリンクを{{typeString}}します。よろしいですか？</p>
            <error-list />
            <administrator-link-confirm />
            <div class="ly_buttonBox">
                <app-button
                    name="キャンセル"
                    @click="moveTo('/admin/links')"
                    ref="jest-link-confirm-cancel"
                    class="cancel"
                />
                <app-button
                    :name="typeString"
                    @click="submit"
                    :class="{delete:isClassDelete}"
                    ref="jest-link-confirm-submit"
                />
            </div>
        </div>
    </default-template>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { move } from '@/util'
import Progress from '@/mixins/progress.js'
import ErrorList from '@/components/molecules/ErrorList'
import AdministratorLinkConfirm from '@/components/organisms/AdministratorLinkConfirm'

export default {
    name: 'AdminLinks',
    mixins:[Progress],
    components:{
        ErrorList,
        AdministratorLinkConfirm,
    },
    computed:{
        ...mapGetters({
            link:'admin/links/link',
        }),
        /**
         * 日付文字列を「YYYY年MM月DD日」の形式に成形
         */
        date(){
            return function (dateString) {
                return dateString ? moment(dateString).format('YYYY年MM月DD日') : '-'
            }
        },
        stringApproved(){
            return function (approved) {
                return approved ? '承認済' : '非承認'
            }
        },
        typeString(){
            return {
                enabled:'承認',
                disabled:'承認取消',
                delete:'削除',
            }[this.$route.meta.type]
        },
        isDelete(){
            return this.$route.meta.type === 'delete'
        },
        isEnabled(){
            return this.$route.meta.type === 'enabled'
        },
        isDisabled(){
            return this.$route.meta.type === 'disabled'
        },
        isClassDelete(){
            return this.isDelete || this.isDisabled
        }
    },
    methods:{
        ...mapActions({
            clearError:'error/clear',
            updateError:'error/update',
            enabledLink:'admin/links/enabled',
            disabledLink:'admin/links/disabled',
            deleteLink:'admin/links/delete',
        }),
        moveTo(path){
            move(this.$router, path)
        },
        submit(){
            this.clearError()
            if (this.isEnabled) {
                this.enabled()
            } else if (this.isDisabled) {
                this.disabled()
            } else {
                this.delete()
            }
        },
        enabled(){
            this.enabledLink()
                .then(response => {
                    if (response.status == 200) {
                        this.updateError(response)
                        this.moveTo('/admin/links')
                    } else {
                        this.updateError(response)
                    }
                })
        },
        disabled(){
            this.disabledLink()
                .then(response => {
                    if (response.status == 200) {
                        this.updateError(response)
                        this.moveTo('/admin/links')
                    } else {
                        this.updateError(response)
                    }
                })
        },
        delete(){
            this.deleteLink()
                .then(response => {
                    if (response.status == 200) {
                        this.updateError(response)
                        this.moveTo('/admin/links')
                    } else {
                        this.updateError(response)
                    }
                })
        }
    }
}
</script>

<style scoped>
.bl_contents_wrapper{
    min-width: 600px;
    max-width: 600px;
}
</style>